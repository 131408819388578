var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_c('div',[_c('table',{attrs:{"id":"my-table"}},[_vm._m(0),_c('tbody',[_vm._l((_vm.report),function(obj,key){return [_vm._l((obj.childs),function(par,y){return [(obj.childs[y].childs.length == 0)?_c('tr',{key:y + 5,attrs:{"y":y}},[(!par.name.includes('temp'))?[(y == 0)?_c('td',{attrs:{"rowspan":_vm.returnRowspanFirst(obj.childs)}},[_vm._v(" "+_vm._s(obj.name == "reports.country" ? _vm.$lang(obj.value) : obj.value)+" ")]):_vm._e(),_c('td',{attrs:{"colspan":(y == 0 &&
                        (par.name.includes('totalCountByYear') ||
                          par.name.includes('countNumberOfFinalDecision'))) ||
                      (y == 1 && par.name.includes('totalCountKnown'))
                        ? 2
                        : 1,"rowspan":_vm.returnRowspanSec(obj.childs[y].childs)}},[_vm._v(" "+_vm._s(_vm.$lang(par.name))+" ")]),(par.value != null)?_c('td',[_vm._v(_vm._s(par.value))]):_vm._e()]:_vm._e()],2):_vm._l((obj.childs[y].childs),function(rep,x){return [(!rep.name.includes('temp'))?_c('tr',{key:key + x,attrs:{"x":x,"y":y}},[[(y == 0 && x == 0)?_c('td',{attrs:{"rowspan":_vm.returnRowspanFirst(obj.childs)}},[_vm._v(" "+_vm._s(obj.name == "reports.country" ? _vm.$lang(obj.value) : obj.value)+" ")]):_vm._e(),(x == 0)?_c('td',{attrs:{"colspan":(y == 0 &&
                          (par.name.includes('totalCountByYear') ||
                            par.name.includes(
                              'countNumberOfFinalDecision'
                            ))) ||
                        (y == 1 && par.name.includes('totalCountKnown'))
                          ? 2
                          : 1,"rowspan":_vm.returnRowspanSec(obj.childs[y].childs)}},[_vm._v(" "+_vm._s(_vm.$lang(par.name))+" ")]):_vm._e(),(par.value != null)?_c('td',[_vm._v(_vm._s(par.value))]):_vm._e()],(!rep.name.includes('temp'))?_c('td',[_vm._v(" "+_vm._s(_vm.$lang(rep.name))+" ")]):_vm._e(),(!rep.name.includes('temp'))?_c('td',[_vm._v(_vm._s(rep.value))]):_vm._e()],2):_vm._e()]})]})]}),(_vm.report.length > 1 && _vm.$route.query.id != 42903)?[_vm._l((_vm.sumData.childs),function(par,y){return [(_vm.sumData.childs[y].childs.length == 0)?_c('tr',{key:_vm.key + y},[(y == 0)?_c('td',{attrs:{"rowspan":_vm.returnRowspanFirst(_vm.sumData.childs)}},[_vm._v(" "+_vm._s(_vm.sumData.value)+" ")]):_vm._e(),(!par.name.includes('temp'))?_c('td',{attrs:{"colspan":(y == 0 &&
                      (par.name.includes('totalCountByYear') ||
                        par.name.includes('countNumberOfFinalDecision'))) ||
                    (y == 1 && par.name.includes('totalCountKnown'))
                      ? 2
                      : 1,"rowspan":_vm.returnRowspanSec(_vm.sumData.childs[y].childs)}},[_vm._v(" "+_vm._s(_vm.$lang(par.name))+" ")]):_vm._e(),(par.value != null && !par.name.includes('temp'))?_c('td',[_vm._v(" "+_vm._s(par.value)+" ")]):_vm._e()]):_vm._l((_vm.sumData.childs[y].childs),function(rep,x){return [(!rep.name.includes('temp'))?_c('tr',{key:_vm.key + x},[(x == 0 && y == 0)?_c('td',{attrs:{"rowspan":_vm.returnRowspanFirst(_vm.sumData.childs)}},[_vm._v(" "+_vm._s(_vm.sumData.value)+" ")]):_vm._e(),(!par.name.includes('temp') && x == 0)?_c('td',{attrs:{"colspan":(y == 0 &&
                        (par.name.includes('totalCountByYear') ||
                          par.name.includes('countNumberOfFinalDecision'))) ||
                      (y == 1 && par.name.includes('totalCountKnown'))
                        ? 2
                        : 1,"rowspan":_vm.returnRowspanSec(_vm.sumData.childs[y].childs)}},[_vm._v(" "+_vm._s(_vm.$lang(par.name))+" ")]):_vm._e(),(par.value != null && !par.name.includes('temp'))?_c('td',[_vm._v(" "+_vm._s(par.value)+" ")]):_vm._e(),(!rep.name.includes('temp'))?_c('td',[_vm._v(" "+_vm._s(_vm.$lang(rep.name))+" ")]):_vm._e(),(!rep.name.includes('temp'))?_c('td',[_vm._v(_vm._s(rep.value))]):_vm._e()]):_vm._e()]})]})]:_vm._e()],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"align":"center"},attrs:{"id":"activity"}}),_c('th',{staticStyle:{"align":"center"}}),_c('th',{staticStyle:{"align":"center"},attrs:{"id":"note"}}),_c('th',{staticStyle:{"align":"center"},attrs:{"id":"note"}})])])}]

export { render, staticRenderFns }