<template>
  <div>
    <span>
      <div>
        <table id="my-table">
          <thead>
            <tr>
              <th id="activity" style="align: center"></th>
              <th style="align: center"></th>
              <th id="note" style="align: center"></th>
              <th id="note" style="align: center"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(obj, key) in report">
              <template v-for="(par, y) in obj.childs">
                <tr :key="y + 5" :y="y" v-if="obj.childs[y].childs.length == 0">
                  <template v-if="!par.name.includes('temp')">
                    <td v-if="y == 0" :rowspan="returnRowspanFirst(obj.childs)">
                      {{
                        obj.name == "reports.country"
                          ? $lang(obj.value)
                          : obj.value
                      }}
                    </td>
                    <td
                      :colspan="
                        (y == 0 &&
                          (par.name.includes('totalCountByYear') ||
                            par.name.includes('countNumberOfFinalDecision'))) ||
                        (y == 1 && par.name.includes('totalCountKnown'))
                          ? 2
                          : 1
                      "
                      :rowspan="returnRowspanSec(obj.childs[y].childs)"
                    >
                      {{ $lang(par.name) }}
                    </td>
                    <td v-if="par.value != null">{{ par.value }}</td>
                  </template>
                </tr>
                <template v-else v-for="(rep, x) in obj.childs[y].childs">
                  <tr
                    :key="key + x"
                    :x="x"
                    :y="y"
                    v-if="!rep.name.includes('temp')"
                  >
                    <template>
                      <td
                        v-if="y == 0 && x == 0"
                        :rowspan="returnRowspanFirst(obj.childs)"
                      >
                        {{
                          obj.name == "reports.country"
                            ? $lang(obj.value)
                            : obj.value
                        }}
                      </td>
                      <td
                        v-if="x == 0"
                        :colspan="
                          (y == 0 &&
                            (par.name.includes('totalCountByYear') ||
                              par.name.includes(
                                'countNumberOfFinalDecision'
                              ))) ||
                          (y == 1 && par.name.includes('totalCountKnown'))
                            ? 2
                            : 1
                        "
                        :rowspan="returnRowspanSec(obj.childs[y].childs)"
                      >
                        {{ $lang(par.name) }}
                      </td>
                      <td v-if="par.value != null">{{ par.value }}</td>
                    </template>
                    <td v-if="!rep.name.includes('temp')">
                      {{ $lang(rep.name) }}
                    </td>
                    <td v-if="!rep.name.includes('temp')">{{ rep.value }}</td>
                  </tr>
                </template>
              </template>
            </template>
            <template v-if="report.length > 1 && $route.query.id != 42903">
              <template v-for="(par, y) in sumData.childs">
                <tr :key="key + y" v-if="sumData.childs[y].childs.length == 0">
                  <td
                    v-if="y == 0"
                    :rowspan="returnRowspanFirst(sumData.childs)"
                  >
                    {{ sumData.value }}
                  </td>
                  <td
                    v-if="!par.name.includes('temp')"
                    :colspan="
                      (y == 0 &&
                        (par.name.includes('totalCountByYear') ||
                          par.name.includes('countNumberOfFinalDecision'))) ||
                      (y == 1 && par.name.includes('totalCountKnown'))
                        ? 2
                        : 1
                    "
                    :rowspan="returnRowspanSec(sumData.childs[y].childs)"
                  >
                    {{ $lang(par.name) }}
                  </td>
                  <td v-if="par.value != null && !par.name.includes('temp')">
                    {{ par.value }}
                  </td>
                </tr>
                <template v-else v-for="(rep, x) in sumData.childs[y].childs">
                  <tr :key="key + x" v-if="!rep.name.includes('temp')">
                    <td
                      v-if="x == 0 && y == 0"
                      :rowspan="returnRowspanFirst(sumData.childs)"
                    >
                      {{ sumData.value }}
                    </td>
                    <td
                      v-if="!par.name.includes('temp') && x == 0"
                      :colspan="
                        (y == 0 &&
                          (par.name.includes('totalCountByYear') ||
                            par.name.includes('countNumberOfFinalDecision'))) ||
                        (y == 1 && par.name.includes('totalCountKnown'))
                          ? 2
                          : 1
                      "
                      :rowspan="returnRowspanSec(sumData.childs[y].childs)"
                    >
                      {{ $lang(par.name) }}
                    </td>
                    <td v-if="par.value != null && !par.name.includes('temp')">
                      {{ par.value }}
                    </td>
                    <td v-if="!rep.name.includes('temp')">
                      {{ $lang(rep.name) }}
                    </td>
                    <td v-if="!rep.name.includes('temp')">{{ rep.value }}</td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import { ResponsiveDirective } from "vue-responsive-components";
import NoData from "@/components/General/NoData";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import VueDocPreview from "vue-doc-preview";
// import image2base64 from "image-to-base64";
import { header } from "@/assets/imgs/imgs.js";
import "jspdf-autotable";
import { mapState } from "vuex";
window["html2canvas"] = require("html2canvas");

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    report: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      nameRoute: ""
    };
  },

  created() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  methods: {
    calcSum() {},
    returnRowspanFirst(obj) {
      var rowNumber = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i] && !obj[i].name.includes("temp") && obj[i].value != null)
          rowNumber++;
        for (let j = 0; j < obj[i].childs.length; j++) {
          if (obj[i].childs[j] && !obj[i].childs[j].name.includes("temp"))
            rowNumber++;
        }
      }
      return rowNumber ? rowNumber : 1;
    },
    returnRowspanSec(obj) {
      var rowNumber = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i] && !obj[i].name.includes("temp")) rowNumber++;
        for (let j = 0; j < obj[i].childs.length; j++) {
          if (obj[i].childs[j] && !obj[i].childs[j].name.includes("temp"))
            rowNumber++;
        }
      }
      return rowNumber ? rowNumber : 1;
    },
    returnLastSum(rootName, name) {
      var sum = 0;
      var $this = this;
      for (let i = 0; i < $this.report.length; i++) {
        for (let j = 0; j < $this.report[i].childs.length; j++) {
          if ($this.report[i].childs[j].name == name) {
            if ($this.report[i].childs[j].value != null)
              sum += Number($this.report[i].childs[j].value);
            else return null;
          }
          for (let k = 0; k < $this.report[i].childs[j].childs.length; k++) {
            if (
              $this.report[i].childs[j].childs[k].name == name &&
              $this.report[i].childs[j].name == rootName
            ) {
              if ($this.report[i].childs[j].childs[k].value != null)
                sum += Number($this.report[i].childs[j].childs[k].value);
              else return null;
            }
          }
        }
      }
      return sum;
    }
  },
  watch: {},
  computed: {
    sumData() {
      var totalSum = {};
      if (this.report.length > 1) {
        var $this = this;
        var yFrom = 0,
          yTo = 0;
        var yTo = Math.max.apply(
          Math,
          $this.report.map(function (o) {
            return Number(o.value);
          })
        );
        var yFrom = Math.min.apply(
          Math,
          $this.report.map(function (o) {
            return Number(o.value);
          })
        );

        var lastObject = {
          value: yFrom + "-" + yTo,
          name: "reports.year",
          childs: $this.$_.cloneDeep($this.report[0].childs)
        };

        for (let i = 0; i < lastObject.childs.length; i++) {
          if (
            lastObject.childs[i].name.includes("temp") ||
            !lastObject.childs[i].name.includes("%")
          )
            lastObject.childs[i].value = $this.returnLastSum(
              "",
              lastObject.childs[i].name
            );

          for (let j = 0; j < lastObject.childs[i].childs.length; j++) {
            if (
              lastObject.childs[i].childs[j].name.includes("temp") ||
              !lastObject.childs[i].childs[j].name.includes("%")
            ) {
              lastObject.childs[i].childs[j].value = $this.returnLastSum(
                lastObject.childs[i].name,
                lastObject.childs[i].childs[j].name
              );
            }
          }
        }
        totalSum = lastObject;

        for (let i = 0; i < totalSum.childs.length; i++) {
          var total = 0,
            totalChild = null;
          for (let j = 0; j < totalSum.childs[i].childs.length; j++) {
            totalChild = totalSum.childs[i].childs.find(function (element) {
              return element.name.includes("totalCountByYear") == true;
            });
            if (totalChild != null) {
              total = totalChild.value;
            } else {
              totalChild = totalSum.childs[i].childs.find(function (element) {
                return element.name.includes("totalCountKnown") == true;
              });
              if (totalChild != null) {
                total = totalChild.value;
              } else {
                total = 0;
              }
            }

            if (
              totalSum.childs[i].childs[j].name.includes("%") &&
              !totalSum.childs[i].childs[j].name.includes("totalCountByYear") &&
              total != 0
            ) {
              totalSum.childs[i].childs[j].value = Math.round(
                parseFloat(
                  totalSum.childs[i].childs.find(function (element) {
                    return (
                      element.name ==
                        totalSum.childs[i].childs[j].name.replace(
                          "%",
                          "temp"
                        ) ||
                      element.name ==
                        totalSum.childs[i].childs[j].name.replace("%", "")
                    );
                  }).value / total
                ) * 100
              );
            }
          }
        }
      }
      return totalSum;
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  text-align: left !important;
}
</style>
